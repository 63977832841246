import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { ButtonTemplate } from '../button/button-template';
import moment from 'moment';
import { GetCountry } from '../../lib/get-country';

const useStyle = makeStyles(theme => ({
    header: {
        fontWeight: 'bold',
    },
    table: {
        border: '1px solid #dfdfdf'
    }
}))

export const ParticipantTable = ({ participants, setCurrentParticipant, setOpenParticipantDialog, setOpenDeleteDialog }) => {
    const classes = useStyle();
    console.log(participants)
    return (
        <TableContainer className={classes.table}>
            <Table aria-label="collapsible table" size='small'>
                <TableHead className={classes.header}>
                    <TableRow>
                        <TableCell className={classes.header}>Fullname</TableCell>
                        <TableCell className={classes.header}>Aliases</TableCell>
                        <TableCell className={classes.header}>Birthday</TableCell>
                        <TableCell className={classes.header}>Country</TableCell>
                        <TableCell className={classes.header}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {participants?.map((participant, index) => (
                        <TableRow>
                            <TableCell>{`${participant?.FirstName || ''} ${participant?.LastName || ''}`}</TableCell>
                            <TableCell>{participant?.NickName}</TableCell>
                            <TableCell>{moment(participant?.Birthday).format('MM/DD/YYYY')}</TableCell>
                            <TableCell>{GetCountry(participant?.Country)?.label}</TableCell>
                            <TableCell>
                                {/* {Actions(row)} */}
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <ButtonTemplate
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                setCurrentParticipant(participant);
                                                setOpenParticipantDialog(true);
                                            }}
                                        >
                                            <Typography className={classes.tableText}>Detail</Typography>
                                        </ButtonTemplate>
                                    </Grid>
                                    <Grid item>
                                        {index !== 0 && <ButtonTemplate
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                setCurrentParticipant(participant)
                                                setOpenDeleteDialog(true)
                                            }}
                                        >
                                            <Typography>Delete</Typography>
                                        </ButtonTemplate>}
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}