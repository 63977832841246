import "video.js/dist/video-js.min.css"

import React, { Component } from "react"

import videojs from "video.js"

//import '@videojs/http-streaming/dist/videojs-http-streaming.min.js';

export default class VideoPlayer extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(
      this.videoNode,
      { ...this.props.playerSettings },
      function onPlayerReady() {
        console.log("onPlayerReady", this)
      }
    )
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    console.debug("VideoPlayer::render::props:", this.props)
    return (
      <div>
        <div data-vjs-player>
          <video
            height="630"
            ref={node => (this.videoNode = node)}
            className="video-js vjs-styles-defaults vjs-big-play-centered"
            // type="application/x-mpegURL"
            type="application/vnd.apple.mpegurl"
            crossOrigin="use-credentials"
          ></video>
        </div>
      </div>
    )
  }
}
