import React, { useEffect, useState } from 'react'
import { Box, TextField } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import { ListUserAdmin, GetUser } from '../../lib/graphql-command/user-command';
import { Autocomplete } from '@material-ui/lab';

export const EditAuthor = ({video, handleInput}) => {
    const [stars, setStars] = useState([])

    useEffect(() => {
        if(!video.VideoID) {
            API.graphql(graphqlOperation(GetUser))
            .then(result => handleInput('UserID', result.data.getUser.UserID))
            .catch(err => console.log(err))
        }

        API.graphql(graphqlOperation(ListUserAdmin, {
            Limit: 10000,
            Page: 1,
            Search: '',
            Filter: 'author'
        }))
        .then(result => {
            setStars(old => ([...old, ...result.data.listUserAdmin.Items]))
        })
        .catch(err => console.log(err))

        // API.graphql(graphqlOperation(ListUserAdmin, {
        //     Limit: 10000,
        //     Page: 1,
        //     Search: '',
        //     Filter: 'admin'
        // }))
        // .then(result => {
        //     setStars(old => ([...old, ...result.data.listUserAdmin.Items]))
        // })
        // .catch(err => console.log(err))
    }, [])

    console.log(stars)
    console.log(stars.filter(star => star.UserID === video.UserID)[0])
    return (
        <Box py={5}>
            {stars.length > 0 && <Autocomplete
                options={stars}
                size="small"
                getOptionLabel={option => `${option.DisplayName} (${option.Email})`}
                value={stars.filter(star => star.UserID === video.UserID)[0]}
                clearOnBlur
                closeIcon={false}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Author"
                    />
                )}
                onChange={(event, value) => value && handleInput('UserID', value.UserID)}
            />}
        </Box>
    )
}