import { Box, Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import { Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { GetTags } from '../../lib/graphql-command/videos-command';
import { ButtonTemplate } from '../button/button-template';
import { TextFieldTemplate } from '../input/textfield-template';

const useStyle = makeStyles(theme => ({
	tagLink: {
        marginRight: '5px',
        marginBottom: '5px',
        display: 'inline-block'
	},
	allTags: {
		border: '1px solid',
		marginTop: '5px'
	}
}))

export const Tags = ({video, setVideo}) => {
    const [tagList, setTagList] = useState(video.Tags.map(tag => ({'TagID': tag.TagID, 'Slug': tag.Slug, 'Name': tag.Name})) || [])
    const [allTags, setAllTags] = useState([])
    const [isShowAllTags, setIsShowAllTags] = useState(false)
    const inputRef = useRef()

    const classes = useStyle();

    useEffect(() => {
        API.graphql(graphqlOperation(GetTags))
        .then(result => {
			setAllTags(result.data.getTagListAdmin?.Items)
		})
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        setVideo(old => ({...old, 'Tags': tagList}))
    }, [tagList])


    const handleAdd = () => {
        console.log(inputRef.current.value)
        const value = inputRef.current.value
        if(value === '') return;
        const slug = value?.replace(/ /g,"_")
        const index = allTags.findIndex(tag => tag.Name === value)
        const index2 = tagList.findIndex(tag => tag.Name === value)
        console.log(index)
        if(index < 0 && index2 < 0) {
            setTagList(old => [
                ...old, {Slug: slug, Name: value}
            ])
        }

        if(index >= 0 && index2 < 0) {
            setTagList(old => [
                ...old, allTags[index]
            ])
        }
        inputRef.current.value = ''
    }

    const handleDeleteTag = (e, tag) => {
        e.preventDefault()
        setTagList(old => old.filter(t => t.Slug !== tag.Slug))
	}

	const handleSelectTag = (e, tag) => {
        e.preventDefault()
        if(!tagList.includes(tag)) {
            setTagList(old => [...old, tag])
        }
    }

    return (
        <Box py={10}>
            <Typography color="primary" variant="h6">Tags</Typography>
            <Box pt={5}>
                <Grid container spacing={5}>
                    <Grid item>
                        <TextFieldTemplate
                            size='small'
                            label='New Tag'
                            inputRef={inputRef}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    handleAdd();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <ButtonTemplate
                            borderradius='50px'
                            variant='contained'
                            color='primary'
                            onClick={handleAdd}
                        >Add</ButtonTemplate>
                    </Grid>
                </Grid>
            </Box>
            <Box pt={5}>
                {tagList.map(tag => (
                    <Chip
                        key={tag.Slug}
                        size="small"
                        className={classes.tagLink}
                        label={tag.Name}
                        onDelete={e => handleDeleteTag(e, tag)}
                    />
                ))}
            </Box>
            <Box pt={10} display='none'>
                <Link to="#" onClick={e => {
                    e.preventDefault();
                    setIsShowAllTags(old => !old)
                }}>Choose from the most used tags</Link>
                <Box className={classes.allTags} display={isShowAllTags ? 'block' : 'none'} p={10}>
                    {allTags.map(tag => (
                        <Link to={tag.Slug} key={tag.Slug} className={classes.tagLink} onClick={e => handleSelectTag(e, tag)}>{tag.Name}</Link>
                    ))}
                </Box>
            </Box>
        </Box>

    )
}