import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#ff410f',
            main: '#ff410f',
            dark: '#e20000',
            contrastText: '#fff'
        },
        secondary: {
            light: '#fff',
            main: '#fff',
            dark: '#e6e6e6',
            contrastText: '#ff410f'
        },
        thirdly: {
            light: '#e8e8e8',
            hover: '#fffff2',
            dark: '#757575',
            background: '#e2e2e2',
            contrastText: '#fff'
        }
    },
    spacing: 2,
    typography: {
        h1: {
            fontSize: '5rem'
        },
        h2: {
            fontSize: '4.5rem'
        },
        h3: {
            fontSize: '3.7rem'
        },
        h4: {
            fontSize: '2.5rem'
        },
        h5: {
            fontSize: '2rem'
        },
        h6: {
            fontSize: '1.5rem'
        },
        body1: {
            fontSize: '1.1rem'
        },
        body2: {
            fontSize: '1rem'
        },
        subtitle1: {
            fontSize: '1.3rem'
        },
        subtitle2: {
            fontSize: '1.1rem'
        },
        caption: {
            fontSize: '0.8rem',
            fontStyle: 'italic'
        },
        button: {
            fontSize: '1rem',
            fontWeight: 'bold',
            textTransform: 'unset'
        }
    },
    props: {
        MuiButton: {
            size: 'large'
        },
        MuiInput: {
            size: 'small'
        }
    },
    container: {
        width: 1280
    }
});

export default theme;