import { Box, Button, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { GetCountry } from '../../lib/get-country'
import moment from 'moment';
import { ButtonTemplate } from '../button/button-template';
import { ParticipantTable } from './participant-table';
import { ParticipantDialog } from './participant-dialog';
import { DeleteParticipantDialog } from './delete-participant-dialog';
import { GetParticipant } from '../../lib/graphql-command/videos-command';
import { API, graphqlOperation } from 'aws-amplify';

const useStyle = makeStyles(theme => ({
    header: {
        fontWeight: 'bold'
    }
}))

const defaultParticipant = {FirstName: '', LastName: '', NickName: '', Birthday: null, Country: '', IDImageOne: null, IDImageTwo: null}

export const Participants = ({participants, setParticipants, video, setVideo}) => {
    const classes = useStyle();
    const [openParticipantDialog, setOpenParticipantDialog] = useState(false);
    const [currentParticipant, setCurrentParticipant] = useState(defaultParticipant);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    useEffect(() => {
        if(video.VideoID) {
            setParticipants([{
                'FirstName': video.FirstName,
                'LastName': video.LastName,
                'NickName': video.NickName,
                'Birthday': video.Birthday,
                'Country': video.Country,
                'IDImageOne': video.IDImageOne,
                'IDImageTwo': video.IDImageTwo
            }])
            for (let i in video.Participants) {
                API.graphql(graphqlOperation(GetParticipant, {ParticipantID: video.Participants[i]})).then(result => {
                    if(result.data.getParticipant) {
                        setParticipants(participantList => ([...participantList, result.data.getParticipant]));
                    }
                })
            }
        }
    }, [])

    useEffect(() => {
        const participantIds = participants.filter(p => p?.ParticipantID).map(p => `${p?.ParticipantID}`)
        setVideo(old => ({...old, 'Participants': participantIds}))
    }, [participants])

    const handleAdd = () => {
        setOpenParticipantDialog(true)
        setCurrentParticipant(defaultParticipant)
    }

    const handleDelete = () => {
        console.log('deleted', currentParticipant)

        setParticipants(oldParticipants => (oldParticipants.filter(p => p.FirstName !== currentParticipant.FirstName)))
        setOpenDeleteDialog(false)
    }

    return (
        <Box>
            <ParticipantTable {...{participants, setCurrentParticipant, setOpenParticipantDialog, setOpenDeleteDialog}} />
            <ParticipantDialog {...{openParticipantDialog, setOpenParticipantDialog, participant: currentParticipant, setParticipants, setCurrentParticipant, setVideo, video}} />
            <DeleteParticipantDialog {...{open: openDeleteDialog, setOpen: setOpenDeleteDialog, handleDelete}}/>
            <Box py={10}>
                <Button
                    variant="contained"
                    size="small"
                    color='primary'
                    onClick={handleAdd}
                >
                    <Typography>Add participant</Typography>
                </Button>
            </Box>
        </Box>
    )
}