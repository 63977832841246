import { Box, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { ButtonTemplate } from '../button/button-template';

export const DeleteParticipantDialog = ({open, setOpen, handleDelete}) => (
    <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
            <Box pt={5}>
                Are you sure you want to delete this participant?
            </Box>
        </DialogContent>
        <DialogActions>
            <Box px={10} pb={5}>
                <Grid container direction='row' spacing={2}>
                    <Grid item>
                        <ButtonTemplate
                            textDecoration='none'
                            color='primary'
                            variant='outlined'
                            size='small'
                            borderradius='25px'
                            changetextcolor='#000'
                            onClick={() => setOpen(false)}
                        >Cancel</ButtonTemplate>
                    </Grid>
                    <Grid item>
                        <ButtonTemplate
                            textDecoration='none'
                            color='primary'
                            variant='contained'
                            size='small'
                            borderradius='25px'
                            changetextcolor='#fff'
                            onClick={handleDelete}
                        >Delete</ButtonTemplate>
                    </Grid>
                </Grid>
            </Box>
        </DialogActions>
    </Dialog>
)