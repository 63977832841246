import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, LinearProgress, makeStyles, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Constants from '../../constants/constants';
import { Alert, Autocomplete } from '@material-ui/lab';
import { API, graphqlOperation } from 'aws-amplify';
import theme from '../../theme/theme';
import { ButtonTemplate } from '../button/button-template';
import _ from 'lodash';
import { getImage } from '../../lib/utils';
import { CreateParticipant, UpdateParticipant } from '../../lib/graphql-command/videos-command';
import { EncodeObject } from '../../lib/encode';
import axios from 'axios';

export const getSession = () => {
    let cookie = getCookie("mlnp_session");

    if (cookie) {
        return cookie;
    }

    console.log("returning local storage: " + localStorage.getItem("mlnp_session"));
    return localStorage.getItem("mlnp_session");
}

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
}

const useStyle = makeStyles(theme => ({
    primaryColor: {
        color: theme.palette.primary.main
    },
    toolTipStyle: {
        maxWidth: '200px',
    },
    iconStyle: {
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    input: {
        display: 'none'
    },
    thumbnail: {
        width: '150px',
        height: 'auto',
        margin: '0 auto',
    },
    DateField: {
        '& .MuiOutlinedInput-adornedEnd': {
            padding: 0
        }
    }
}))

const bucket = {
    video: process.env.GATSBY_BUCKET_VIDEO,
    image: process.env.GATSBY_BUCKET_IMAGE
}

const LinearProgressWithLabel = (props) => (
    <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>
    </Box>
);

const defaultInput = {FirstName: '', LastName: '', NickName: '', Birthday: null, Country: '', IDImageOne: null, IDImageTwo: null}

export const ParticipantDialog = ({openParticipantDialog, setOpenParticipantDialog, participant, setParticipants, setCurrentParticipant, setVideo, video}) => {
    const [participantInput, setParticipantInput] = useState(participant);
    const [alertMessage, setAlertMessage] = useState(false);
    const [uploadFileName, setUploadFileName] = useState({IDImageOne: '', IDImageTwo: ''});
    const [uploadLink, setUploadLink] = useState({IDImageOne: '', IDImageTwo: ''});
    const [loadingProgress, setLoadingProgress] = useState({IDImageOne: null, IDImageTwo: null});
    const [helperText, setHelperText] = useState({FirstName: '', LastName: '', Birthday: '', Country: '', IDImageOne: '', IDImageTwo: ''});
    const [disabled, setDisabled] = useState(false);
    const [inputCountry, setInputCountry] = useState(null);

    const classes = useStyle();

    useEffect(() => {
        setParticipantInput(participant)
        if (participant.Country && participant.IDImageOne && participant.IDImageTwo) {
            setInputCountry(Constants.Countries.filter(country => country.code === participant.Country)[0]);
            setUploadLink({
                'IDImageOne': getImage(participant.IDImageOne),
                'IDImageTwo': getImage(participant.IDImageTwo)
            })
        }
    }, [participant]);

    const nameField = [
        { label: 'First name', key: 'FirstName' },
        { label: 'Last name', key: 'LastName' }
    ]

    const idField = [
        { label: 'Upload ID one', key: 'IDImageOne', image: uploadLink.IDImageOne },
        { label: 'Upload ID two', key: 'IDImageTwo', image: uploadLink.IDImageTwo }
    ]

    const handleParticipantInput = (item, value) => {
        setParticipantInput(participantInput => ({
            ...participantInput,
            [item]: value
        }))
        setHelperText(helperText => ({
            ...helperText,
            [item]: ''
        }))
        setAlertMessage(false);
    }

    const handleUpload = (item, file, maxSize, maxSizeText) => {
        if (file) {
            handleParticipantInput(item, '');
            setUploadFileName(uploadFileName => ({
                ...uploadFileName,
                [item]: ''
            }))
            setUploadLink(uploadLink => ({
                ...uploadLink,
                [item]: ''
            }))
            setLoadingProgress(loadingProgress => ({
                ...loadingProgress,
                [item]: null
            }))

            if (file.size > maxSize) {
                setHelperText(helperText => ({
                    ...helperText,
                    [item]: `Your file is larger than maximum size ${maxSizeText}`
                }))
                return false;
            }

            axios.get(`https://makelovenotporn.tv/api/uploads?type=${file.name.split(".").pop()}`, {
                headers: {
                    Authorization: `Basic ${getSession()}`,
                }
            }).then(function (signed) {
                var config = {
                    onUploadProgress: function(e) {
                        setLoadingProgress(loadingProgress => ({
                            ...loadingProgress,
                            [item]: (100 * e.loaded) / e.total,
                        }))
                    }
                };
                console.log("signed: ", signed.data.result.url);
                axios.put(signed.data.result.signedRequest, file, config).then(function (res) {
                    console.log("upload result: ", res);
                    setUploadLink(uploadLink => ({
                        ...uploadLink,
                        [item]: signed.data.result.url,
                    }));
                    handleParticipantInput(item, {
                        region: "us-west-2",
                        bucket:
                        file.type.split("/")[0] === "video"
                            ? bucket.video
                            : bucket.image,
                        key: new URL(signed.data.result.url).pathname.replace(/^\//, ""),
                    })
                    setUploadFileName(uploadFileName => ({
                        ...uploadFileName,
                        [item]: file.name,
                    }))
                })
                .catch(function (err) {
                    console.log(`error uploading file: ${err}`);
                });
            });
        }
    }

    const mandatoryFields = [
        { label: 'First Name', key: 'FirstName' },
        { label: 'Last Name', key: 'LastName' },
        { label: 'Birthday', key: 'Birthday' },
        { label: 'Country', key: 'Country' },
        { label: 'Image ID one', key: 'IDImageOne' },
        { label: 'Image ID two', key: 'IDImageTwo' },
    ]

    const checkHelperText = (item) => {
        let error = false;
        if (!participantInput[item.key]) {
            setHelperText(helperText => ({
                ...helperText,
                [item.key]: `${item.label} is required`
            }));
            error = true;
        }

        if ((Date.now() - new Date(participantInput.Birthday))/(31557600000) < 18) {
            setHelperText(helperText => ({
                ...helperText,
                Birthday: 'Age should be 18 years old or older.'
            }))
            error = true;
        }

        if (participantInput.Birthday === 'Invalid date') {
            setParticipantInput(participantInput => ({
                ...participantInput,
                Birthday: null
            }))
            setHelperText(helperText => ({
                ...helperText,
                Birthday: 'Please enter your birthday in the requested format - MM/DD/YYYY'
            }))
            error = true;
        }

        if (error) {
            return false;
        }

        setHelperText(helperText => ({
            ...helperText,
            [item]: ''
        }))
        return true;
    }

    const isNew = () => {
        return _.isEqual(participant, defaultInput)
    }

    const handleAdd = () => {
        let isValid = true;
        for (let i in mandatoryFields) {
            if (!checkHelperText(mandatoryFields[i])) {
                isValid = false;
            }
        }

        if (isValid) {
            setDisabled(true)
            if((!isNew() && !participant.ParticipantID) || video.FirstName === '') {
                setParticipants(participants => {
                    let newParticipants = [...participants]
                    newParticipants[0] = participantInput
                    return newParticipants
                })
                setVideo(oldVideo => ({...oldVideo, ...participantInput}))
                handleClose();
            } else {
                const endPoint = isNew() ? CreateParticipant : UpdateParticipant
                API.graphql(graphqlOperation(endPoint, EncodeObject(participantInput)))
                .then(result => {
                    setParticipants(participants => {
                        if(isNew()) {
                            return [...participants, result.data.createParticipant]
                        } else {
                            const editedIndex =  participants.findIndex(p => p.FirstName === participant.FirstName)
                            let newParticipants = [...participants]
                            newParticipants[editedIndex] = participantInput
                            return newParticipants
                        }
                    })
                    handleClose();
                })
                .catch(err => console.log(err))
            }
        } else {
            setAlertMessage(true);
            setDisabled(false);
        }
    }

    const handleClose = () => {
        setDisabled(false)
        setOpenParticipantDialog(false);
        setHelperText({FirstName: '', LastName: '', Birthday: '', Country: '', IDImageOne: '', IDImageTwo: ''});
        setInputCountry(null);
        setUploadLink({IDImageOne: '', IDImageTwo: ''});
        setUploadFileName({IDImageOne: '', IDImageTwo: ''});
        setParticipantInput(defaultInput);
        setCurrentParticipant(defaultInput);
        setAlertMessage(false);
    }
    return (
        <Dialog
            open={openParticipantDialog}
            // onClose={() => setOpenParticipantDialog(false)}
            fullWidth
            aria-labelledby='form-dialog-title'
        >
            <DialogTitle id='form-dialog-title'>
                {/* {state === 'edit' ? 'Edit Participant' : 'Add New Participant'} */}
                {alertMessage &&
                    <Box pt={5}>
                        <Alert severity='error'>Please complete required fields before saving</Alert>
                    </Box>
                }
            </DialogTitle>
            <DialogContent>
                <Box pb={5}>
                    {/* {ParticipantDialogContent()} */}
                    <Box pt={5}>
                        <Grid container direction='row' justify='space-around' spacing={10}>
                            {nameField.map(item => (
                                <Grid item xs={12} md={6} key={item.key}>
                                    <TextField
                                        fullWidth
                                        label={item.label}
                                        size='small'
                                        variant='outlined'
                                        value={participantInput[item.key]}
                                        helperText={helperText[item.key]}
                                        error={helperText[item.key] === '' ? false : true}
                                        onChange={e => handleParticipantInput(item.key, e.target.value)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box pt={15}>
                        <TextField
                            fullWidth
                            size='small'
                            variant='outlined'
                            key='NickName'
                            label='Aliases, pseudonyms, etc'
                            value={participantInput.NickName}
                            onChange={e => handleParticipantInput('NickName', e.target.value)}
                        />
                    </Box>
                    <Box pt={15}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                openTo='year'
                                variant='inline'
                                inputVariant='outlined'
                                format='MM/dd/yyyy'
                                size='small'
                                fullWidth
                                label='Date of birth'
                                value={participantInput.Birthday}
                                helperText={helperText.Birthday}
                                className={classes.DateField}
                                error={helperText.Birthday === '' ? false : true}
                                // defaultValue={moment().subtract(18, 'years')}
                                initialFocusedDate={moment().subtract(18, 'years')}
                                maxDate={moment().subtract(18, 'years')}
                                onChange={date => {
                                    if (date) {
                                        if (date.toString() === 'Invalid Date') {
                                            setHelperText(helperText => ({
                                                ...helperText,
                                                Birthday: 'Please enter your birthday in the requested format - MM/DD/YYYY'
                                            }))
                                        } else {
                                            setHelperText(helperText => ({
                                                ...helperText,
                                                Birthday: ''
                                            }))
                                        }
                                        setParticipantInput(participantInput => ({
                                            ...participantInput,
                                            Birthday: moment(date).format('YYYY-MM-DD')
                                        }))
                                    }
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                    <Box pt={15}>
                        <Autocomplete
                            options={Constants.Countries}
                            value={inputCountry}
                            size='small'
                            getOptionLabel={option => option.label}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Country'
                                    className={classes.textfieldStyle}
                                    helperText={helperText.Country}
                                    error={helperText.Country === '' ? false : true}
                                />
                            )}
                            onChange={(event, value) => {
                                setInputCountry(value)
                                if (value) {
                                    handleParticipantInput('Country', value.code)
                                } else {
                                    handleParticipantInput('Country', '')
                                }
                            }}
                        />
                    </Box>
                    <Box pt={7}>
                        <Grid container direction='row' justify='space-around' spacing={10}>
                            {idField.map(item => (
                                <Grid item key={item.key} xs={12} md={6}>
                                    <Grid container direction='column' justify='center' alignItems='center'>
                                        <Grid item>
                                            <Box pb={5}>
                                                <input
                                                    className={classes.input}
                                                    id={`participant-${item.key}`}
                                                    multiple
                                                    type='file'
                                                    accept='.jpg,.jpeg,.png,.gif'
                                                    onChange={e => handleUpload(item.key, e.target.files[0], 5000000, '5Mb')}
                                                />
                                                <label htmlFor={`participant-${item.key}`}>
                                                    <ButtonTemplate
                                                        color='primary'
                                                        variant='contained'
                                                        borderradius='25px'
                                                        size='small'
                                                        component='span'
                                                        changetextcolor={theme.palette.primary.contrastText}
                                                    >
                                                        {`${item.label}*`}
                                                    </ButtonTemplate>
                                                </label>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction='column' justify='center' alignItems='flex-start'>
                                                {!uploadFileName[item.key] ?
                                                    <FormHelperText error>{helperText[item.key]}</FormHelperText>
                                                :
                                                    <Box style={{width: '100%'}}>
                                                        {/* <Typography variant='caption' align='center' style={{whiteSpace: 'break-spaces'}}>{uploadFileName[item.key]}</Typography> */}
                                                        {item.image === '' &&
                                                            <Box style={{width: '100%'}}>
                                                                <LinearProgressWithLabel value={loadingProgress[item.key]} />
                                                            </Box>
                                                        }
                                                    </Box>
                                                }
                                                <img src={item.image} alt='' className={classes.thumbnail} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box px={5} pb={5}>
                    <Grid container direction='row' justify='flex-end'>
                        <Grid item>
                            <Box px={1}>
                                <ButtonTemplate
                                    textDecoration='none'
                                    color='primary'
                                    variant='outlined'
                                    size='small'
                                    borderradius='25px'
                                    changetextcolor='#000'
                                    onClick={handleClose}
                                >Cancel</ButtonTemplate>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box px={1}>
                                {/* {state === 'edit' ?
                                    <ButtonTemplate
                                        textDecoration='none'
                                        color='primary'
                                        variant='contained'
                                        size='small'
                                        borderradius='25px'
                                        changetextcolor='#fff'
                                        onClick={() => handleEdit()}
                                        disabled={disabled}
                                    >Save</ButtonTemplate>
                                :
                            } */}
                            <ButtonTemplate
                                textDecoration='none'
                                color='primary'
                                variant='contained'
                                size='small'
                                borderradius='25px'
                                changetextcolor='#fff'
                                onClick={() => handleAdd()}
                                disabled={disabled}
                            >Save</ButtonTemplate>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogActions>
        </Dialog>
    )
}