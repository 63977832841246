import { Box, FormHelperText, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { createRef } from 'react';
import { LinearProgressWithLabel } from '../progress/linear-progress-with-label';
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { getUrlVideoByHeight } from '../../lib/utils';
import { ButtonTemplate } from '../button/button-template';
import VideoPlayer from './video-player';

const useStyle = makeStyles(theme => ({
    input: {
        display: 'none'
    }
}))

export const UpdateVideoArea = ({video, handleUpload, uploadFileName, uploadLink, helperText, loadingProgress, handleInput}) => {
    const VideoIntroRef = createRef(null)
    const VideoRealRef = createRef(null)
    const classes = useStyle();
    const uploadForm = [
        {
          label: "Full Video",
          key: "VideoReal",
          accept: "video/*",
          video: video?.VideoReal,
          ref: VideoRealRef,
        },
        {
          label: "Trailer",
          key: "VideoIntro",
          accept: "video/*",
          tooltip: "Minimum 3 minutes",
          video: video?.VideoIntro,
          ref: VideoIntroRef,
        },
    ]

    const cloudFrontEndpoint = process.env.GATSBY_CLOUDFRONT_DOMAIN;
    const VideoPlayerSetting = (source) => {
        return {
            fluid: true,
            controls: true,
            playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
            controlBar: {
                children: [
                    'playToggle',
                    'currentTimeDisplay',
                    'timeDivider',
                    'durationDisplay',
                    'progressControl',
                    'volumePanel',
                    'playbackRateMenuButton',
                    'qualitySelector',
                    'pictureInPictureToggle',
                    'fullscreenToggle'
                ]
            },
            sources: [
                {
                    src: cloudFrontEndpoint + getUrlVideoByHeight(720, source),
                    withCredentials: true,
                }
            ],
        };
    }

    const getVideoDuration = (file) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function() {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;
          handleInput("VideoDuration", Math.floor(duration));
        }
        video.src = URL.createObjectURL(file);
    }

    return (
        <Box>
            {uploadForm.map(item => (
                <Box pt={10} key={item.key}>
                    <Grid container direction="row" alignItems="center">
                        <Typography color="primary" variant="h6">
                            {item.label}
                        </Typography>
                    </Grid>
                    <Box pt={7}>
                    <Grid
                        container
                        direction="row"
                        alignItems="flex-start"
                        spacing={10}
                    >
                        <Grid item xs={12} sm={3}>
                        <Box pb={5}>
                            <Grid container justify="center">
                            <input
                                // accept={item.accept}
                                className={classes.input}
                                id={item.key}
                                multiple
                                type="file"
                                accept=".mp4,.webm,.ogv,.mov,.qt,.3g2,.3gp,.avi,.f4v,.flv,.m4v,.mkv,.mpg,.mpeg,.wmv"
                                onChange={e => {
                                    handleUpload(item.key, e.target.files[0], 5000000000, "5Gb");
                                    if (item.key === "VideoReal") {
                                        getVideoDuration(e.target.files[0]);
                                    }
                                }}
                            />
                            <label htmlFor={item.key}>
                                <ButtonTemplate
                                color="primary"
                                variant="contained"
                                borderradius="25px"
                                size="small"
                                component="span"
                                padding="5px 27px"
                                >
                                Upload
                                </ButtonTemplate>
                            </label>
                            </Grid>
                        </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                        {!uploadFileName[item.key] ? (
                            <FormHelperText error className={classes.textCenterXs}>
                            {helperText[item.key]}
                            </FormHelperText>
                        ) : (
                            <Grid item>
                            <Grid
                                container
                                direction="row"
                                className={classes.objectCenterSM}
                            >
                                <Grid item>
                                <Typography
                                    variant="caption"
                                    align="center"
                                    className={classes.textCenterXs}
                                >
                                    {uploadFileName[item.key]}
                                </Typography>
                                </Grid>
                                {uploadLink[item.key] &&
                                uploadLink[item.key] !== "" ? (
                                <Grid
                                    container
                                    className={classes.objectCenterSM}
                                >
                                    <Typography
                                    variant="caption"
                                    className={classes.SuccessText}
                                    >
                                    Done
                                    </Typography>
                                    <CheckCircleIcon
                                    className={classes.SuccessText}
                                    fontSize="small"
                                    />

                                </Grid>
                                ) : (
                                <Box style={{ width: "50%" }}>
                                    <LinearProgressWithLabel
                                    value={loadingProgress[item.key]}
                                    />
                                </Box>
                                )}
                            </Grid>
                            </Grid>
                        )}
                        </Grid>
                    </Grid>
                    </Box>
                    <Grid container>
                        <Grid item md={10}>
                            {uploadLink[item.key] && (
                                <video
                                width='100%'
                                src={uploadLink[item.key]}
                                className={classes.VideoUploadStyle}
                                ref={item.ref}
                                crossOrigin="use-credentials"
                                controls
                                />
                            )}
                            <br />
                            {!uploadLink[item.key] && item.video?.key && <VideoPlayer {...{target: item.label, playerSettings: VideoPlayerSetting(item.video)}}/>}
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </Box>
    )
}