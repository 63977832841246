import { Box, FormHelperText, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { getImage } from '../../lib/utils';
import { ButtonTemplate } from '../button/button-template';
import { LinearProgressWithLabel } from '../progress/linear-progress-with-label';

const useStyle = makeStyles(theme => ({
    input: {
        display: 'none'
    },
    thumbnail: {
        width: "150px",
        height: "auto",
        [theme.breakpoints.down("sm")]: {
          justifyContent: "center",
        },
      },
    
      objectCenterSM: {
        [theme.breakpoints.down("sm")]: {
          justifyContent: "center",
        },
      },
    
      textCenterXs: {
        [theme.breakpoints.down("xs")]: {
          textAlign: "center",
        },
      },
    
      DialogTitleStyle: {
        backgroundColor: theme.palette.primary.main,
      },
    
      DialogTitleTextStyle: {
        color: theme.palette.primary.contrastText,
        fontSize: "1em",
      },
    
      DialogContentStyle: {
        paddingTop: 0,
      },
    
      linkStyle: {
        textDecoration: "none",
        color: theme.palette.primary.main,
      },
    
      SuccessText: {
        color: theme.palette.success.main,
      },
    
      primaryColor: {
        color: theme.palette.primary.main,
      },
    
      VideoUploadStyle: {
        display: "none",
      },
      truncateText: {
        width: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      error: {
        fontStyle: 'italic',
        fontSize: '0.9rem',
        color: theme.palette.primary.main,
        '& li': {
          lineHeight: '1.5rem'
        }
      }
}))

export const EditStills = ({video, uploadFileName, handleUpload, uploadLink, loadingProgress, helperText}) => {
    const classes = useStyle()

    const StillListButton = () => {
        const stillList = []
        for (let i = 0; i < 5; i++) {
          stillList.push(
            <Box py={5} key={i}>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                spacing={10}
              >
                <Grid item xs={12} sm={4}>
                  <Box pb={7}>
                    <Grid container justify="center">
                      <input
                        accept="image/*"
                        className={classes.input}
                        id={`still-${i}`}
                        multiple
                        type="file"
                        accept=".jpg,.jpeg,.png,.gif"
                        onChange={e =>
                          handleUpload(
                            `Still_${i}`,
                            e.target.files[0],
                            5000000,
                            "5Mb"
                          )
                        }
                      />
                      <label htmlFor={`still-${i}`}>
                        <ButtonTemplate
                          color="primary"
                          variant={i === 0 ? "contained" : "outlined"}
                          borderradius="25px"
                          size="small"
                          component="span"
                          padding={i === 0 ? "5px 35px" : "5px 13px"}
                        >
                          {i === 0 ? "Main Still" : "Additional Still"}
                        </ButtonTemplate>
                      </label>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                    {!uploadLink[`Still_${i}`] && video.Stills[i] && <img
                        src={getImage(video.Stills[i])}
                        alt=""
                        className={classes.thumbnail}
                    />}
                    {!uploadFileName[`Still_${i}`] ? (
                        <FormHelperText error className={classes.textCenterXs}>
                        {helperText[`Still_${i}`]}
                        </FormHelperText>
                    ) : (
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                className={classes.objectCenterSM}
                            >
                                <Grid item xs={12} className={classes.textCenterXs}>
                                <Typography variant="caption" align="center">
                                    {uploadFileName[`Still_${i}`]}
                                </Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.textCenterXs}>
                                {uploadLink[`Still_${i}`] &&
                                uploadLink[`Still_${i}`] !== "" ? (
                                    <img
                                    src={uploadLink[`Still_${i}`]}
                                    alt=""
                                    className={classes.thumbnail}
                                    />
                                ) : (
                                    <Box style={{ width: "50%" }}>
                                    <LinearProgressWithLabel
                                        value={loadingProgress[`Still_${i}`]}
                                    />
                                    </Box>
                                )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
              </Grid>
            </Box>
          )
        }
        return stillList
    }

    return (
        <Box pt={10}>
            <Typography color="primary" variant="h6">
                Stills
            </Typography>
            {StillListButton()}
        </Box>
    )
}